//@author: devin

import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgZone } from "@angular/core";
import { ServiceLoader } from "../../services/service.loader";
import { S25Util } from "../../util/s25-util";
import { LangService } from "../../services/lang.service";
import { ModalMap, ModalName } from "./modal.map";
import { jSith } from "../../util/jquery-replacement";

export interface ModalData {
    lang?: any;
    isUnsupportedBrowser?: boolean;
    closeModal?(): void;
    callBack?(): void;
    modalInstance?: NgbModalRef;
    title?: string;
    iconClass?: string;
}

export class ModalService {
    private static zone: NgZone = undefined;
    private static ngbModal: NgbModal = undefined;
    private static returnToFocusElement: any = null;

    public static modal(modalType: ModalName, data?: any) {
        ModalService.zone = ModalService.zone || ServiceLoader.get(NgZone);
        ModalService.ngbModal = ModalService.ngbModal || ServiceLoader.get(NgbModal);
        ModalService.returnToFocusElement = document.activeElement;

        return ModalService.zone.run(() => {
            return LangService.getLang().then((lang) => {
                let component: any = typeof modalType === "string" ? ModalMap.map[modalType].controller : modalType;
                let modalRef: NgbModalRef = ModalService.ngbModal.open(component, {
                    animation: true,
                    keyboard: true,
                    size: ModalMap.map[modalType].size || "lg",
                    backdrop: modalType === "dialog" || (ModalMap.map[modalType].asDialog ? "static" : true),
                    windowClass: modalType || "generic-modal",
                });

                if (modalRef.componentInstance) {
                    data = data || {};
                    if (modalType === "dialog") {
                        data.answer = 0;
                    }
                    if (ModalMap.map[modalType].defaultData)
                        data =
                            (ModalMap.map[modalType].defaultData &&
                                S25Util.coalesceDeep(data, ModalMap.map[modalType].defaultData)) ||
                            data;
                    data.title ??= ModalMap.map[modalType].title;
                    data.iconClass ??= ModalMap.map[modalType].iconClass;
                    data.lang = lang; //inject lang into all modals but dont include in merge above bc that would be expensive
                    data.isUnsupportedBrowser = S25Util.isUnsupportedBrowser();
                    data.closeModal = function (result?: any) {
                        return modalRef.close(result);
                    };
                    data.modalInstance = modalRef;
                    modalRef.componentInstance.data = data;
                }

                modalRef.result.finally(() => {
                    ModalService.returnToFocusElement && ModalService.returnToFocusElement.focus();
                });

                return modalRef.result;
            });
        });
    }

    public static dialogType = function (type: string, data: any, primary?: string) {
        data = data || {};
        let options = type.split(" ");
        data.buttonMap = {};
        jSith.forEach(options, function (_, val) {
            switch (val) {
                case "Yes":
                    data.buttonMap.hasYes = 1;
                    break;
                case "No":
                    data.buttonMap.hasNo = 1;
                    break;
                case "Cancel":
                    data.buttonMap.hasCancel = 1;
                    break;
                case "Continue":
                    data.buttonMap.hasContinue = 1;
                    break;
                case "OK":
                    data.buttonMap.hasOK = 1;
                    break;
                default:
                    break;
            }
        });
        data.buttonMap.primary = primary;
        return data;
    };
}
