//@author: devin
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalDialogComponent } from "./modals/modal.dialog.component";
import { ModalRecommendedBrowsersComponent } from "./modals/modal.recommended.browsers.component";
import { ModalHeaderComponent } from "./modal.header.component";
import { S25HelpModule } from "../s25-help/s25.help.module";
import { ModalImageUploadComponent } from "./modals/modal.image.upload.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25ImageSelectorModule } from "../s25-image-selector/s25.image.selector.module";
import { S25ImageUploadModule } from "../s25-image-upload/s25.image.upload.module";
import { ModalCancelRequestComponent } from "./modals/modal.cancel.request.component";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { ModalCabinetSelectComponent } from "./modals/modal.cabinet.select.component";
import { ModalDraftConflictsComponent } from "./modals/modal.draft.conflicts.component";
import { S25ConflictsModule } from "../s25-conflicts/s25.conflicts.module";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25PipesModule } from "../../pipes/s25.pipes.module";
import { S25InfiniteScrollModule } from "../s25-infinite-scroll/s25.infinite.scroll.module";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { ModalRegisterEventComponent } from "./modals/modal.register.event.component";
import { S25TableModule } from "../s25-table/s25.table.module";
import { ModalEditRuleComponent } from "./modals/modal.edit.rule.component";
import { S25RuleTreeModule } from "../s25-rule-tree/s25.rule.tree.module";
import { ModalCreateObjectComponent } from "./modals/modal.create.object.component";
import { S25ContactInfoModule } from "../s25-contact-info/s25.contact.module";
import { ModalEditEventFormConfigComponent } from "./modals/modal.edit.event.form.config.component";
import { S25EventFormConfigsModule } from "../system-settings/event-form-settings/s25-event-form-config/s25.event.form.configs.module";
import { ModalEditEmailTemplateComponent } from "./modals/modal.edit.email.template.component";
import { S25EmailTemplateModule } from "../system-settings/event-save-email/s25-email-template/s25.email.template.module";
import { ModalEmailTemplatePreviewComponent } from "./modals/modal.email.template.preview.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { ModalEmailScenarioComponent } from "./modals/modal.email.scenario.component";
import { ModalEditMasqueradeComponent } from "./modals/modal.edit.masquerade.component";
import { ModalEditBuildingComponent } from "./modals/modal.edit.building.component";
import { ModalSwitchRoleComponent } from "./modals/modal.switch.roles.component";

import { S25EmailScenarioModule } from "../system-settings/event-save-email/s25-email-scenario/s25.email.scenario.module";
import { S25BuildingModule } from "../s25-building/s25.building.module";
import { S25MasqueradeModule } from "../S25-masquerade/masquerade.module";

import { ModalEmailScenarioPreviewComponent } from "./modals/modal.email.scenario.preview.component";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { ModalEditDocumentComponent } from "./modals/modal.edit.document.component";
import { S25DocumentManagementModule } from "../system-settings/document-management/s25.document.management.module";
import { ModalEditEmbeddedConfigComponent } from "./modals/modal.edit.embedded.config.component";
import { S25EmbeddingModule } from "../system-settings/embedding/s25-embedding/s25.embedding.module";
import { ModalInactiveTaskComponent } from "./modals/modal.inactive.task.component";
import { S25RadioModule } from "../s25-radio/s25.radio.module";
import { ModalEventFormMappingComponent } from "./modals/modal.event.form.mapping.component";
import { ModalOptimizerEffectiveDatingNgComponent } from "./modals/modal.optimizer-effectived-dating.component";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";

@NgModule({
    declarations: [
        ModalHeaderComponent,
        ModalDialogComponent,
        ModalRecommendedBrowsersComponent,
        ModalImageUploadComponent,
        ModalCancelRequestComponent,
        ModalCabinetSelectComponent,
        ModalDraftConflictsComponent,
        ModalRegisterEventComponent,
        ModalEditRuleComponent,
        ModalCreateObjectComponent,
        ModalEditEventFormConfigComponent,
        ModalEditEmailTemplateComponent,
        ModalEmailTemplatePreviewComponent,
        // ModalEditSetComponent,
        ModalEmailScenarioComponent,
        ModalEmailScenarioPreviewComponent,
        ModalEditDocumentComponent,
        ModalEditEmbeddedConfigComponent,
        ModalInactiveTaskComponent,
        ModalEventFormMappingComponent,
        ModalOptimizerEffectiveDatingNgComponent,
        ModalEditBuildingComponent,
        ModalEditMasqueradeComponent,
        ModalSwitchRoleComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        S25HelpModule,
        S25ToggleButtonModule,
        S25ImageSelectorModule,
        S25EditableModule,
        S25ImageUploadModule,
        S25LoadingInlineModule,
        S25ConflictsModule,
        S25MultiselectModule,
        S25PipesModule,
        S25InfiniteScrollModule,
        S25DropdownPaginatedModule,
        S25TableModule,
        S25RuleTreeModule,
        S25ContactInfoModule,
        S25EventFormConfigsModule,
        S25EmailTemplateModule,
        S25CheckboxModule,
        S25RadioModule,
        S25EmailScenarioModule,
        S25SimpleCollapseModule,
        S25DocumentManagementModule,
        S25EmbeddingModule,
        // S25MasterDefinitionsModule,
        S25DatepickerModule,
        S25BuildingModule,
        S25MasqueradeModule,
    ],
    exports: [ModalHeaderComponent],
    providers: [ModalHeaderComponent],
})
export class ModalModule {
    constructor() {}
}
